import React from "react"
import Title from "../../title"
import Card from "./../../card"
import Img from "gatsby-image"
import useSectors from "../../../hooks/use-sectors"
import "./aboutUs.scss"

const BG_CARD_COLORS = [
  "bg-blue-primary",
  "bg-blue-secondary",
  "bg-gray-secondary ",
]

const AboutUs = () => {
  const sectors = useSectors()

  return (
    <section className="aboutUs">
      <div class="container">
        <div className="m-1">
          <Title color="blue" title="¿Quiénes Somos?" withBorder={true} />
        </div>
        <p
          className="m-auto text-center"
          style={{
            marginBottom: 71.993,
            lineHeight: 1.6,
            maxWidth: "59.24rem",
          }}
        >
          Somos una empresa mexicana dedicada al manejo de productos biológicos
          y amigables, para la biorremediación, limpieza, cuidado y
          mantenimiento de plantas tratadoras, trampas de grasa, drenajes,
          cárcamos y todo tipo de superficies contaminadas por grasa, aceites,
          mantecas, petróleo, gasolina, etc. Tenemos 25 años de experiencia en
          la implementación industrial de programas para la bioaumentación y el
          cuidado del agua.
        </p>
      </div>
      <div class="container">
        <div className="grid grid--center">
          {sectors &&
            sectors.map(({ name, description, image }, index) => (
              <div
                className="col-10 col-sm-6 col-md-4 col-lg-3 mt-2 mt-md-0"
                key={name}
              >
                <Card className="h-100 m-auto" style={{ maxWidth: "20.8rem" }}>
                  <Img fluid={image.fluid} className="card--image-top" />
                  <div
                    className={`card__body pt-xs-2 ${
                      index === 2 ? "color-blue-primary" : "color-light"
                    } ${BG_CARD_COLORS[index]}`}
                  >
                    <h2
                      style={{ fontSize: "1.4rem" }}
                      className="text-center mb-xs-1 text-uppercase"
                    >
                      {name}
                    </h2>
                    <p className="pl-xs-2 pr-xs-2">{description}</p>
                  </div>
                </Card>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default AboutUs
