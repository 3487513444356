import { graphql, useStaticQuery } from "gatsby"

const useBanners = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsBanner {
        nodes {
          title
          description
          titlecolor {
            hex
          }
          descriptioncolor {
            hex
          }
          image {
            fluid(maxWidth: 1535, maxHeight: 750) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  return data.allDatoCmsBanner.nodes.map(
    ({ title, description, image, titlecolor, titledescription }) => ({
      title,
      description,
      image,
      titlecolor,
      titledescription,
    })
  )
}

export default useBanners
