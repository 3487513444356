import React from "react"
import Slider from "react-slick"
import BackgroundImage from "gatsby-background-image"
import "./carousel.scss"
import useBanners from "../../../hooks/use-banners"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: "slick-dots",
  nextArrow: null,
  prevArrow: null,
}

const Carousel = () => {
  const banners = useBanners()

  console.log(banners)

  return (
    <section className="carousel">
      <Slider {...settings} className="carousel__body">
        {banners.map(banner => (
          <BackgroundImage
            Tag="div"
            className="carousel__background"
            key={banner.title}
            fluid={banner.image.fluid}
            backgroundSize="contain"
            backgroundPosition="top center"
          >
            <div className="carousel__item">
              {
                <div className="carousel__content">
                  <h1
                    className="carousel__title"
                    style={{
                      color: banner?.titlecolor?.hex ?? "#00345e",
                    }}
                  >
                    {banner.title}
                  </h1>
                  <p
                    className="carousel__description"
                    style={{
                      color: banner?.titlecolor?.hex ?? "#00345e",
                    }}
                  >
                    {banner.description}
                  </p>
                </div>
              }
            </div>
          </BackgroundImage>
        ))}
      </Slider>
    </section>
  )
}

export default Carousel
