import React from "react"
import AboutUs from "../components/sections/aboutUs"
import Layout from "../components/layout"
import FeaturedProducts from "../components/sections/featuredProducts"
import Separator from "../components/separator"
import Carousel from "../components/sections/carousel"
import OurServices from "../components/sections/ourServices"
import Services from "../components/sections/services"
import Products from "../components/sections/products"
import Laboratiorio from "../components/sections/laboratorio"
import Contact from "../components/sections/contact"

const IndexPage = () => (
  <Layout>
    <Carousel />
    <AboutUs />
    <Separator />
    <FeaturedProducts />
    <Products />
    <OurServices />
    <Services />
    <Laboratiorio />
    <Contact />
  </Layout>
)

export default IndexPage
