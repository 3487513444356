import React from "react"
import "./separator.scss"

const Separator = () => (
  <section className="separator">
    <div className="separator__line" />
  </section>
)

export default Separator
