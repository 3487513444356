import React from "react"
import Title from "../../title"
import "./products.scss"
import useSections from "../../../hooks/use-sections"
import Card from "../../card"
import Img from "gatsby-image"

const Products = () => {
  const sections = useSections()

  return (
    <section className="products">
      <div class="container">
        <Title title="productos" withBorder={true} color="blue" />
        <div
          className="grid grid__row-gap mt-xs-1 mb-xs-3"
          style={{ maxWidth: 1200 }}
        >
          {sections &&
            sections.map(({ title, image }) => (
              <div className="col-12 col-sm-6 col-lg-4">
                <Card key={title} className="card__border-0">
                  <Img className="card__image" fluid={image.fluid} />
                  <div class="card__body bg-blue-primary">
                    <h3
                      style={{ fontSize: "1rem" }}
                      className="text-center p-xs-1 text-uppercase color-light"
                    >
                      {title}
                    </h3>
                  </div>
                </Card>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Products
