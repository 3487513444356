import React from "react"
import Product from "../../product"
import Title from "../../title"
import "./featuredProducts.scss"
import useProducts from "./../../../hooks/use-FeaturedProducts"

const FeaturedProducts = () => {
  const productList = useProducts()

  return (
    <section className="featuredProducts">
      <div class="container">
        <Title title="Productos Destacados" color="blue" />
        <div className="featuredProducts__grid">
          {productList &&
            productList.map(product => (
              <Product
                key={product.nombre}
                name={product.nombre}
                image={product.imagen.fluid}
              />
            ))}
        </div>
      </div>
    </section>
  )
}

export default FeaturedProducts
