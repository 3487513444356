import React from "react"
import Title from "./../../title"
import Slider from "./slider"
import "./services.scss"

const Services = () => {
  return (
    <div className="services">
      {/*       <Title
        title="SERVICIOS"
        style={{
          fontSize: "3.5rem",
          alignItems: "flex-end",
          paddingRight: "5rem",
        }}
        withBorder={true}
        color="gray"
      /> */}
      <Slider />
    </div>
  )
}

export default Services
