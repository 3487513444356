import React from "react"
import "./laboratorio.scss"
import Button from "./../../button"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const Laboratorio = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Foto-Laboratorio.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      className="laboratorio"
      Tag="section"
      fluid={data.file.childImageSharp.fluid}
    >
      <div className="laboratorio__overlay" />
      <div className="laboratorio__body">
        <h2 className="laboratorio__title">Laboratorio</h2>
        <div className="laboratorio__button">
          <Button className="button--blue">CONOCER SERVICIOS</Button>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Laboratorio
