import { graphql, useStaticQuery } from "gatsby"

const useFeaturedProducts = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsProducto(limit: 5, filter: { featuredproduct: { eq: true } }) {
        nodes {
          nombre
          imagen {
            fluid(maxWidth: 191, maxHeight: 247) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  return data.allDatoCmsProducto.nodes.map(({ nombre, imagen }) => ({
    nombre,
    imagen,
  }))
}

export default useFeaturedProducts
