import React from "react"
import useDealers from "../../../hooks/use-dealers"
import Button from "../../button"
import Modal from "../../modal"
import Img from "gatsby-image"
import { useStaticQuery } from "gatsby"

const DealersModal = () => {
  const { modalIsOpen, openModal, closeModal } = useDealers()

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mapa_biochem.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Button onClick={openModal}>DISTRIBUIDORES</Button>
      <Modal
        title="DISTRIBUIDORES"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <Img fluid={data.file.childImageSharp.fluid} />
      </Modal>
    </React.Fragment>
  )
}

export default DealersModal
