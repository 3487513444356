import { graphql, useStaticQuery } from "gatsby"

const useServices = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsService(sort: { order: ASC, fields: meta___createdAt }) {
        nodes {
          title
          description
          imagecolor {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          imagewhite {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)
  return data.allDatoCmsService.nodes.map(
    ({ title, description, imagecolor, imagewhite }) => ({
      title,
      description,
      imagecolor,
      imagewhite,
    })
  )
}

export default useServices
