import { graphql, useStaticQuery } from "gatsby"

const useSections = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSection(sort: { order: ASC, fields: title }) {
        nodes {
          title
          image {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  return data.allDatoCmsSection.nodes
}

export default useSections
