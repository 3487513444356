import React from "react"
import useServices from "../../../hooks/use-services"
import Title from "../../title"
import "./ourServices.scss"
import Img from "gatsby-image"

const OurServices = () => {
  const services = useServices()

  return (
    <section className="ourServices" id="servicios">
      <div class="container">
        <Title title="NUESTROS SERVICIOS" withBorder={true} color="light" />
        <div className="ourServices__grid p-2" style={{ maxWidth: 999.5 }}>
          {services.map(service => (
            <div className="ourServices__item" key={service.title}>
              <Img
                fluid={service.imagecolor.fluid}
                className="ourServices__image"
              />
              <h3 className="ourServices__title">{service.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default OurServices
