import React from "react"
import Title from "./../../title"
import Button from "./../../button"
import Map from "./../../map"
import Phone from "./../../icon/phone"
import Location from "./../../icon/location"
import "./contact.scss"

import DealersModal from "./DealersModal"
import BranchOfficeModal from "./BranchOfficeModal"

const Contact = () => {
  return (
    <section className="contact" id="contacto">
      <div className="contact__header">
        <div className="contact__title">
          <Title title="CONTACTO" withBorder={true} color="gray" align="left" />
        </div>
        <div className="contact__ad">
          <p className="contact__ad-text">
            ¿Te gustaría formar parte del equipo de biochem?
          </p>
          <Button> ME INTERESA </Button>
        </div>
      </div>
      <div className="contact__body">
        <div className="contact__address">
          <h4 className="contact__ad-text contact__ad-text--secondary">
            MATRIZ
          </h4>
          <div className="contact__info contact__info--first">
            <Location className="contact__icon" />
            <p className="contact__info-text">
              Blvd. Vilo Alessio Robles 3720 Espacio. 24 PLaza Industrial
              Saltillo, Nazario San Ortiz Garza, 25100 Saltillo, Coah.
            </p>
          </div>
          <div className="contact__info">
            <Phone className="contact__icon" />
            <p className="contact__info-text">
              (844) 439 2100 <br />
              (844) 439 2190
            </p>
          </div>
        </div>
        <div className="contact__map">
          <Map />
        </div>
      </div>
      <div className="contact__footer">
        <DealersModal />
        {/*  <BranchOfficeModal /> */}
      </div>
    </section>
  )
}

export default Contact
