import { graphql, useStaticQuery } from "gatsby"

const useSectors = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSector(sort: { order: ASC, fields: meta___createdAt }) {
        nodes {
          name
          description
          image {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  return data.allDatoCmsSector.nodes
}

export default useSectors
