import React from "react"

const Map = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.3058165359885!2d-100.99940868514896!3d25.46146188377403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86886d3bdea11927%3A0x644677d0295df977!2sBio%20Chem%20International!5e0!3m2!1ses-419!2smx!4v1614620985476!5m2!1ses-419!2smx"
    width="100%"
    height="300"
    title="Map"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
  />
)
export default Map
