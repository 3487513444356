import React from "react"
import clsx from "clsx"

const Card = ({ className, children, ...rest }) => (
  <div className={clsx(className, "card")} {...rest}>
    {children}
  </div>
)

export default Card
