import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Carousel from "react-slick"
import Img from "gatsby-image"
import "./slider.scss"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: "slick-dots",
}

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsService {
        nodes {
          title
          description
          imagecolor {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          image {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  const services = data.allDatoCmsService.nodes
  console.log(services)

  return (
    <div className="servicesSlider">
      <Carousel {...settings}>
        {services &&
          services.map(service => (
            <div className="servicesSlider__slide">
              <div className="servicesSlider__image">
                <Img
                  fluid={service.image.fluid}
                  className="servicesSlider__image-fluid"
                />
              </div>
              <div className="servicesSlider__content">
                <Img
                  fluid={service.imagecolor.fluid}
                  className="servicesSlider__icon"
                />
                <h3 className="servicesSlider__title">{service.title}</h3>
                <p className="servicesSlider__description">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  )
}

export default Slider
