import { useState } from "react"

const useDealers = () => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)

  const closeModal = () => setIsOpen(false)

  return {
    modalIsOpen,
    openModal,
    closeModal,
  }
}

export default useDealers
